<template>
  <div>
    <h2 class="col-lg-12 text-center mb-3">{{ ('Edit Schedule') }}</h2>
    <FormSchedule :baseSchedule="schedule" @submit-requested="submitRequested"></FormSchedule>
  </div>
</template>
 
<script>
import { mapState, mapActions } from 'vuex'
import FormSchedule from '../forms/FormSchedule.vue'
import router from '@/router';

  
  export default {  
    data () {
    return {
      schedule: null
    }
    
  },
  components: { FormSchedule },
  computed:{
    ...mapState('schedule', ['scheduleOutput']),
  },
  methods: {
    ...mapActions('schedule', ['editSchedule']),
    ...mapActions('schedule', ['getList']),
    submitRequested (schedule) {
      this.editSchedule(schedule);
    },
  },
  mounted: function()
  {
    if(!this.$route.params.schedule)
    {
      router.push({ path: '/schedule/list' });
    }
    else
    {
      this.schedule = this.$route.params.schedule;
    }
  }
}
</script> 
 
<style scoped>
</style> 