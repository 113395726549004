<template>
<div>
    <CCol sm="12">
        <CCard>
            <CCardBody class="d-flex flex-column">
                <div>
                    <div role="group" class="form-group">
                        <ImageCrop ref="imageCrop" v-model="imageData" @fileChanged="fileChanged" :ratio="1/1" ratioDisplay="1:1" v-bind:class="{'is-invalid': isImageValid == false }"></ImageCrop>
                        <!-- Name -->
                        <div class="mt-2 mb-2">
                            <label class=""> {{ ('Name') }}</label>
                            <input type="text" v-model="schedule.name" class="form-control" :class="{'is-invalid': submitted && $v.schedule.name.$error  }"/>
                            <div v-if="submitted && !$v.schedule.name.required" class="invalid-feedback">Schedule name is required</div>
                        </div>
                        <!-- Title -->
                        <div class="mt-2 mb-2">
                            <label class=""> {{ ('Title') }}</label>
                            <input type="text" v-model="schedule.title" class="form-control" :class="{ 'is-invalid': submitted && $v.schedule.title.$error }"/>
                            <div v-if="submitted && !$v.schedule.title.required" class="invalid-feedback">Title is required</div>
                        </div>
                        <!-- Info -->
                        <div class="mt-2 mb-2">
                            <label class=""> {{ ('Info') }}</label>
                            <input type="text" v-model="schedule.info" class="form-control" :class="{ 'is-invalid': submitted && $v.schedule.info.$error }"/>
                            <div v-if="submitted && !$v.schedule.info.required" class="invalid-feedback">Info is required</div>
                        </div>
                        <!-- Desc -->
                        <div class="mt-2 mb-2">
                            <label class=""> {{ ('Desc') }}</label>
                            <input type="text" v-model="schedule.desc" class="form-control" :class="{ 'is-invalid': submitted && $v.schedule.desc.$error }"/>
                            <div v-if="submitted && !$v.schedule.desc.required" class="invalid-feedback">Description is required</div>
                        </div>
                        <!-- Date -->
                        <div class="mt-2 mb-2">
                            <label class=""> {{ ('Date') }}</label>
                            <date-picker placeholder="Select date & time" v-model="schedule.time" :config="options" :class="{ 'is-invalid': submitted && $v.schedule.time.$error }"></date-picker>
                            <div v-if="submitted && !$v.schedule.time.required" class="invalid-feedback">Date is required</div>
                        </div>
                    </div>
                </div>
                <!-- Action button + loading -->  
                <CSpinner v-if="loading" variant="success" small class="align-self-end"></CSpinner>
                <CButton v-else type="button" class="btn btn-primary align-self-end" @click="submitClicked">{{ ('Accept Changes') }}</CButton>
            </CCardBody>
        </CCard>
    </CCol>
    <ConfirmationPopUp :confirmationMessage="this.confirmationMessage" @closeConfirmRequested="onClickConfirmationPopup" v-model="showConfirmationPopup"></ConfirmationPopUp>
</div>
</template>
 
<script>
import { UTILS_HELPER } from "@/helpers/utils.js";
import ImageCrop from "../controls/ImageCrop.vue";
import { mapState } from 'vuex'
import ConfirmationPopUp from "@/views/controls/popups/ConfirmationPopUp.vue";
import router from '@/router/index';
import InputSchedule from "@/classes/InputSchedule";

import {
  required, 
} from 'vuelidate/lib/validators'

  export default {
  components: { ConfirmationPopUp, ImageCrop },
    props:{    
         baseSchedule:
        {
            type: Object,
            default: null,
        }
    },
    data()                                                                                  
    {
        return {
            imageData: '',
            selectedFile: '',
            submitted: false,
            schedule: {name:"", title:"", info: "", desc:"", time:""},
            loading: false,
            showConfirmationPopup: false,
            confirmationMessage:"",
            options: {
                    format: 'YYYY-MM-DDTHH:mm:ss',
                    useCurrent: false,
                    showClear: true,
                    showClose: true,
                } ,
        }
    },
        validations: {
                schedule: {
                    name: { required },
                    title: { required },
                    info: { required } ,
                    desc: { required },
                    time: { required }
                },
        },
        
    mounted: function()
    {
        this.resetSchedule();
    },
    computed:
    {
        ...mapState({ 
            alertType: state => state.alert.type,
            alertOutput: state => state.alert.output
        }),
        errorOutput: function()
        {
            var result = [];

            if(this.alertType == 'alert-danger' && this.alertOutput && Array.isArray(this.alertOutput))
            {
                result = this.alertOutput.reduce(function(accum, currentVal) {
                accum[currentVal.key] = currentVal.value;
                    return accum;
                }, {});
            }

            return result;
        },
        ...mapState('schedule', ['processTask']),

        isImageValid: function(){
        return this.imageValidator() && this.errorValidator('image');
    },
    },
    methods:{
        resetSchedule(){

            if(this.baseSchedule){

                this.schedule = UTILS_HELPER.deepClone(this.schedule, this.baseSchedule);
                console.log(this.schedule.photo);
                this.imageData = process.env.VUE_APP_ROOT + this.schedule.photo;

            }

        },
        fileChanged(val)
            {
                this.selectedFile = val;
            },

            submitClicked(){
            this.submitted = true;
           
            
             this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            } 
            // Emit event
            if(this.schedule)
            {
                this.loading = true;

                 var id = this.baseSchedule && this.$route.params.id ? this.$route.params.id : null;
                 var input = new InputSchedule(id, this.schedule, this.selectedFile);

                this.$emit('submit-requested', input);
            }

        },

        onClickConfirmationPopup(value){
            this.showConfirmationPopup = value;     
            router.push('/schedule/list');
        },

        errorValidator(fieldName)
        {
        var result = undefined;

        if(this.alertOutput && Array.isArray(this.alertOutput)) // Error alertOutput
        {
            if(this.alertOutput.find(obj => {
                return obj.key === fieldName
            }))
            {
                result = false;
            }
        }

        return result;
        },

          imageValidator(){
            var result = undefined;

            if(this.submitted && !this.imageData)
            {
                result = false;
            }

            return result;
        },
    },
    watch:
    {
        processTask: function(val)
        {
            if(val)
            {
                this.showConfirmationPopup = true;
                this.confirmationMessage = "Data saved successfully!"

            }
        },
       
        baseSchedule: function()
        {
            this.resetSchedule();
        },

        alertType: function()
        {
            this.loading = false;
        },
        alertOutput: function()
        {
            this.loading = false;

            // Show pop up in case it is a string error
            // In case it is an array, it is shown on the field
            if(typeof this.alertOutput === 'string' || this.alertOutput instanceof String)
            {
                alert(this.alertOutput);
            }
        }
    }
  }
</script> 
 
<style scoped>

    /deep/div.is-invalid > .base-image-input > span {
        background-color: palevioletred;
    }

    .base-image-input {
        display: block;
        width: 200px;
        height: 200px;
        cursor: pointer;
        background-size: cover;
        background-position: center center;
    }

    .showDropdown
    {
        display: block !important;
    }

    .input-group-append
    {
      height: fit-content;
    }

    img
    {
        width: 50%;
        height: 50%;
    }

</style> 